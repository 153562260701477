<template>
    <div class="seting">
        <BackBar :pageTit="headTit" />
        <div class="nickName">
            <input placeholder="请输入新用户名" v-model="nameVal"/>
        </div>
        <div class="btn sure_btn" @click="sureBtn">保存</div>
        <div class="btn cancel_btn" @click="$router.back(-1)">取消</div>
    </div>
</template>
<script>
// @ is an alias to /src
import BackBar from "@/components/BackBar.vue";
import Api from '@/api'
export default {
    name:'Nickname',
    data(){
        return{
            headTit:'修改昵称',
            nameVal:''
        }
    },
    components: {
        BackBar,
    },
    mounted(){
        var that=this
        Api.go('my_goal',false,(res)=>{
            
            that.nameVal=res.me.title
            

        })
    },
    methods:{
        sureBtn(){
            var that=this;
                    Api.go('change_info',{
                        type:'title',
                        v:this.nameVal
                    },()=>{

                        that.$router.back(-1)
                    })
        
        }
    }
}
</script>
<style lang="less" scoped>
    .seting{
        .nickName{
            background: #fff;
            padding:0 .32rem;
            height: .88rem;
            display: flex;
            align-items: center;
            input{
                font-size: .24rem;
                display: block;
            }
        }
        .btn{
            width: 6.86rem;
            height: .96rem;
            line-height: .96rem;
            border-radius: .48rem;
            font-size: .32rem;
            text-align: center;
            margin: 0 auto;
            &.sure_btn{
                background: #F65102;
                color: #fff;
                margin: .48rem auto .32rem;    
            }
            &.cancel_btn{
                background: rgba(0, 0, 0, .1);
                color: #333;
            }
        }
    }
</style>